import { useState, useEffect } from "react";


import axios from 'axios';



import config from "../config.json";

axios.defaults.withCredentials = true;


const BASE_URL = config["api.endport"]

const PASSWD_LOGIN_URL = BASE_URL + "/admin/rpc/passwd-login";
const TOKEN_LOGIN_URL = BASE_URL + "/admin/rpc/token-login";
const LOGOUT_URL = BASE_URL + "/admin/rpc/logout";

const UPLOAD_BINARY_URL = BASE_URL + "/admin/file/binary";
const UPLOAD_ASSET_URL = BASE_URL + "/admin/file/asset";
const UPLOAD_CONFIG_URL = BASE_URL + "/admin/file/config";
const UPLOAD_GENERIC_URL = BASE_URL + "/v1/file";

const SEND_SMS_URL = BASE_URL + "/rpc/send-sms"; 
const SMS_LOGIN_URL = BASE_URL + "/rpc/sms-login"; 

const ORDER_URL = BASE_URL + "/rest/order"; 

const HISTORY_URL = BASE_URL + "/rpc/history";


interface Json {[key:string]:any};


const rpcUrls:Json = {

  "sms-login":SMS_LOGIN_URL,
  "token-login":TOKEN_LOGIN_URL,
  "passwd-login":PASSWD_LOGIN_URL,
  "logout":LOGOUT_URL,
  "send-sms":SEND_SMS_URL
};


interface RpcReq{
  method:string, 
  args:{[key:string]:object},
}

export const uploadBinary = (f:File,cb:(arg:string)=>void) => {
  upload(UPLOAD_BINARY_URL,f,cb);
};


export const uploadAsset = (f:File,cb:(arg:string)=>void) => {
  upload(UPLOAD_ASSET_URL,f,cb);
};

export const uploadConfig = (f:File,cb:(arg:string)=>void) => {
  upload(UPLOAD_CONFIG_URL,f,cb);
};

export const uploadGeneric = (f:File,type:String,suffix:String,progress:(progressEvent: any)=>void,cb:(arg:Json)=>void,err?:(arg:String)=>void) => {
  progressUpload(UPLOAD_GENERIC_URL + '/' + type + '/' + suffix,f,progress,cb,err);
};

export const upload = (url:string,f:File,cb:(arg:string)=>void) => {

  let formData = new FormData()
    let file = f;

    formData.append("file", file)

    console.log( file);

    return axios({
      url:url,
      method:'post',
      data:formData
    }).then(resp=>{
      console.log("upload 2 data: " + JSON.stringify( resp));

      cb ('' + resp.data);
    }).catch(err=>{
      console.log("upload 2 error:" + err);
    });
};

export const progressUpload = (url:string,f:File,progress:(progressEvent: any)=>void,cb:(arg:Json)=>void,err?:(arg:String)=>void) => {

  let formData = new FormData()
    let file = f;

    formData.append("file", file)


    return axios({
      url:url,
      method:'post',
      data:formData,
      onUploadProgress: progress
    }).then(resp=>{
      console.log("upload 2 data: " + JSON.stringify( resp));

      cb (resp.data);
    }).catch(err=>{
      console.log("upload 2 error:" + err);
      err(err);
    });
};


export const restCall = (res:string,method:string, params?:Json, args?:Json ,
  scb?:(args:any)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  let config:Json = {
    method:method,
    baseURL:BASE_URL,
    url: res.startsWith('/')?res: '/rest/' + res,
    withCredentials:true
  }

  if (params){
    config['params'] = params;
  }
  if (args){
    config['data'] = args;
  }

  axios(config).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));

    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}

export const rpcCall = (method:string, args:Json,
  scb:(args:Json)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  axios.post(rpcUrls[method],args,{
    withCredentials:true
  }).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));

    scb(response.data)

  },response =>{
    console.log("===failure=== " + JSON.stringify( response));

    if (fcb){
      fcb(response.message);
    }
  }).catch(function (error) {
    console.log("===error=== " + error);
    
    if (ecb){
      ecb(error);
    }
  });

}
export const passwdLogin = (args:Json,scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  rpcCall("passwd-login",args,scb,fcb,ecb);
}

export const tokenLogin = (args:Json,scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  rpcCall("token-login",args,scb,fcb,ecb);
}

export const logout = (scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{

  rpcCall("logout",{},scb,fcb,ecb);
}

export const sendSms = (args:Json,scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{
  rpcCall("send-sms",args,scb,fcb,ecb);

}


export const smsLogin = (args:Json,scb:(arg:Json)=>void,fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{
  rpcCall("sms-login",args,scb,fcb,ecb);
}

 



export const history = ()=>{
  return axios.get(HISTORY_URL,{
    withCredentials:true
     
  }).then(response => {

    console.log("===data=== " + JSON.stringify( response.data));
    return response.data;
  }).catch(function (error) {
    console.log("===error=== " + error);
  });
}


// export const listOrders = ( filter?:{[key:string]:any},scb:resp=>void )=>{
//   return restCall('/rest/order','get',filter,scb);
// }

export const restDelete = (res:string,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'delete',null,null,scb,fcb);
}

export const restPatch = (res:string,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'patch',null,args,scb,fcb);
}

export const restPost = (res:string,params?:Json,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'post',params,args,scb,fcb);
}


export const restPostV1 = (options:{res:string,params?:Json,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void})=>{
  restCall(options.res,'post',options.params,options.args,options.scb,options.fcb);
}

export const restPut = (res:string,params?:Json,args?:Json,scb?:(args:any)=>void,fcb?:(args:any)=>void)=>{
    restCall(res,'put',params,args,scb,fcb);
}

export const useRestGet = (res:string)=>{
  const [item,setItem] = useState<Json>({});

  const getItem = (params?:Json)=>{
    restCall(res,'get',params,null,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItem(resp);
    })
  }

  let ret:[Json,(args?:Json)=>void] = [item,getItem]
  

  return ret;  
}

export const useRestList = (res:string)=>{
  const [items,setItems] = useState<[]>([]);

  const listItems = (params?:Json)=>{
    restCall(res,'get',params,null,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItems(resp);
    })
  }

  let ret:[[],(args?:Json)=>void] = [items,listItems]
  

  return ret;  
}

export const useRestPagiList = (res:string)=>{
  const [items,setItems] = useState<[]>([]);

  const listItems = (params?:Json)=>{
    restCall(res,'get',params,null,resp=>{
      console.log('get item, resp:' + JSON.stringify(resp));
      setItems(resp);
    })
  }

  let ret:[[],(args?:Json)=>void] = [items,listItems]
  

  return ret;  
}

export const useRestSearch = (res:string)=>{
  const [items,setItems] = useState<Json>({page:0,size:0,pages:0,items:[]});

  const listItems = (filter:{[key:string]:any},page:number,size:number,fcb?:(args:any)=>void)=>{
    restCall(res,'get',{...filter,_page:page,_size:size},null,resp=>{
      console.log('list items, resp:' + JSON.stringify(resp));
      setItems(resp);
    },fcb)
  }

  let ret:[Json,(arg:any,page:number,size:number,fcb?:(args:any)=>void )=>void] = [items,listItems]
  

  return ret;
}


