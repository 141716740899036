import {useContext} from 'react'
import {
  IonContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, 
  warningOutline, warningSharp,
  barChartOutline ,documentTextOutline,phonePortraitOutline, 
  personOutline, peopleOutline, logOutOutline, homeOutline,
  volumeLowOutline ,gridOutline, airplaneOutline,settingsOutline,
  bugOutline } from 'ionicons/icons';
import './Menu.css';

import {AppContext} from '../global';


import magimeta from '../magi-meta.json'

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  icon: string;
  roles?: string[];
}

const appPages: AppPage[] = [
  {
    title: '首页',
    url: '/home',
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    icon:'home.png',
    "roles":["root","tech","oper","manuf"]
  },

  ...magimeta.menu_pages.map((key:string)=>{
    return {
      title: magimeta.pages[key as keyof typeof magimeta.pages].name,
      icon: magimeta.pages[key as keyof typeof magimeta.pages].icon,
      url:'/' + key,
      iosIcon: gridOutline,
      mdIcon: gridOutline,
      roles: magimeta.pages[key as keyof typeof magimeta.pages].roles
    }
  }),
  {
    title: '工具',
    url: '/tool',
    iosIcon: homeOutline,
    mdIcon: homeOutline,
    icon:'tool.png',
    "roles":["root","tech","oper","manuf"]
  },
  
  
];

const actions = [

  {
    title: '登出',
    url:'/logout',
    icon: logOutOutline
  }
];

const Menu: React.FC = () => {
  const location = useLocation();


  const {data,setData} = useContext(AppContext);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Zec Admin</IonListHeader>
          <IonNote>后台管理</IonNote>
          {appPages.map((appPage, index) => {

            var iconPath = '/assets/menu/' + appPage.icon;

            if (appPage.roles == null ||  appPage.roles.indexOf( data.role ) == -1 ){
              return;
            }

            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  {/* <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} /> */}
                  <IonImg slot='start' src={iconPath} className='menu-img' />
                  
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        {/* <IonList id="labels-list">
          <IonListHeader>Actions</IonListHeader>
          {actions.map((action, index) => (
            <IonItem lines="none" key={index} routerLink={action.url}>
              <IonIcon slot="start" icon={action.icon} />
              <IonLabel>{action.title}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
