// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
// https://javascript.plainenglish.io/use-constructor-in-react-functional-component-with-custom-hooks-e0770e92b840
// https://blog.csdn.net/qq_20567691/article/details/106035547

import {useEffect,useState,useMemo,useContext} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonBackButton,IonButtons,IonIcon,IonItem,IonButton,

  IonLabel,IonInput,IonSelect,IonSelectOption,
  IonDatetime,
  useIonToast,
  IonCheckbox
} from '@ionic/react';


import { happy,chevronBackOutline } from 'ionicons/icons';

import { useHistory,useLocation } from "react-router";



import {restPatch,uploadAsset,uploadBinary, uploadGeneric} from "../hooks/AdminHttp";
import enums from '../type/enums';

import styles from '../common/styles.module.css'
import { AppContext } from '../global';
import { genericPatchForeign } from '../hooks/GenericHttp';
import NullableTextField from '../components/NullableTextField';
import NullableSelect from '../components/NullableSelect';
import GenericField from '../components/GenericField';


interface Json {[key:string]:any};

const GenericPatch: React.FC<{
  meta:Json,
  field:string,
  item:Json,
  entities:Json,
  onCloseClicked:()=>void
}> = ({meta,field, item,entities, onCloseClicked}) => {


  console.log('field:' + field);

  const [state,setState] = useState<{[key:string]:any}>(item?item:{});

  const [toastPresent,toastDismiss] = useIonToast();

  let fieldMeta = meta.fields[field ];


  console.log('field meta:' + JSON.stringify(fieldMeta));

  useEffect(()=>{
    if ('file' === fieldMeta.type){
      setState({...state, [field]:null});
    }

  },[]);




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>

          <IonTitle>更新{meta.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onCloseClicked}>取消</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>



        

        <IonItem>
          <IonLabel position="floating">{fieldMeta.name}</IonLabel>
          <GenericField  value={state[field]} setValue={(v:any,...attachs:any)=>{
            console.log('set value: ' + v);
            if (fieldMeta.attach_field && attachs){
              setState({...state,[field]: v, [fieldMeta.attach_field]:attachs[0]});

            }else{
              setState({...state,[field]: v});
            }
            
            console.log('attachs: ' + attachs);
          }} fieldMeta={fieldMeta} entities={entities[(fieldMeta.key ||  field) + 's']} />

        </IonItem>


      

       
      </IonContent>


      <IonItem lines="none">
          <IonButton slot="end"  size="default" color="secondary" expand="block" fill="solid" onClick={()=>{
          

            // orderPresent({cssClass:styles.orderModal,showBackdrop:true});

            if (fieldMeta.type === 'foreign'){
              genericPatchForeign(meta.key, item.uuid, field, state[field],(resp:any)=>{
                console.log('resp: ' + resp);
                toastPresent({
                  message:"提交成功", 
                  duration: 3000,
                  color:'success' 
                });
  
                onCloseClicked();
  
              },(msg:any)=>{
                toastPresent({
                  message:msg, 
                  duration: 3000,
                  color:'danger' 
                });
              });
            }else{

              let fieldsstr = field;
              let fieldsvalue = {[field]:state[field]};

              if (fieldMeta.attach_field){
                fieldsstr += ',' + fieldMeta.attach_field;
                fieldsvalue[fieldMeta.attach_field] = state[fieldMeta.attach_field] ;
              }


              restPatch(meta.patch_path + '/' + item.uuid + '/' + fieldsstr ,fieldsvalue,(resp:any)=>{
                console.log('resp: ' + resp);
                toastPresent({
                  message:"提交成功", 
                  duration: 3000,
                  color:'success' 
                });
  
                onCloseClicked();
  
              },(msg:any)=>{
                toastPresent({
                  message:msg, 
                  duration: 3000,
                  color:'danger' 
                });
              });
            }

            
          }} >确认</IonButton>
      </IonItem>

    </IonPage>
  );
};


export default GenericPatch;