import {useEffect, useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow,IonItem,IonLabel,IonInput,IonButton,
  useIonToast, useIonLoading} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';

import styles from './Login.module.css';

import {AppContext} from '../global';
  

import {useHistory, useLocation} from 'react-router';
import {logout} from '../hooks/AdminHttp';


interface Json {[key:string]:any};

const Logout: React.FC = () => {

  // const { name } = useParams<{ name: string; }>();


  const history = useHistory();

  const {data,setData,status,setStatus} = useContext(AppContext);
  
  const [toastPresent,toastDismiss] = useIonToast();

  const [present, dismiss] = useIonLoading();

  useEffect(() => {
    present();
    logout(d=>{
      
      dismiss();

      if (d.code == 200){
        setData({...data,token:null});
        setStatus({...status,login:false});
        window.location.href="/";

        console.log('logout...');

      }else{
        toastPresent(d.message);
      }
    });
  }, []);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>登出</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
       
      </IonContent> 
    </IonPage>
  );
};

export default Logout;
