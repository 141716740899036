// https://ionicframework.com/docs/react/your-first-app
// https://mattferderer.com/difference-between-array-types-in-typescript

import { useState, useEffect } from "react";

import { Storage } from "@capacitor/storage";



export function useStorage(key:string) {

  const [json, setJson] = useState<{[key:string]:any}>();



  const saveJson = async (_json?:{[key:string]:any}) => {

    // let v:{[key:string]:any} = {};
    // Object.assign(v,json);
    // Object.assign(v,_json);

    console.log('storage write, key:' + key + ', value:' + JSON.stringify(_json) );
  
    setJson(_json);
    await Storage.set({key:key,value: JSON.stringify(_json)});
  
  }
   
  useEffect(()=>{
    (async ()=>{

      
      let {value} = await Storage.get({key:key});

      console.log('storage read, key:' + key + ", value:" + value );

      
      if (value){

        setJson(JSON.parse(value)); 
      }else{
        setJson({});
      }

    })().then(()=>{
      // console.log('use state called4 !!');
    });;
  },[]);

  let ret:[{[key:string]:any},(arg:any)=>void] = [json,saveJson]
  
  return ret;
}

