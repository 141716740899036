import {useEffect,useContext} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow ,IonButton,IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
  IonItem,
  useIonToast,
  IonIcon} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';

import {AppContext} from '../global';


import '../common/styles.css';

import styles from './Home.module.css';

import {useHistory, useLocation} from 'react-router';
import {useRestGet,logout} from '../hooks/AdminHttp';
import { logOutOutline, personOutline } from 'ionicons/icons';

const Home: React.FC = () => {

  const {data,setData,status,setStatus} = useContext(AppContext);

  const history = useHistory();

  const [home,getHome] = useRestGet('/admin/rest/home');

  const [toastPresent,toastDismiss] = useIonToast();

  useEffect(()=>{
    getHome();
  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonMenuButton slot="start"/>
          <IonTitle>首页</IonTitle>
          <IonButtons slot="end">
          <IonButton >
              <IonIcon slot="start" icon={personOutline} />
              <IonLabel>{data.email}[{data.role}]</IonLabel>
            </IonButton>

            <IonButton  onClick={()=>{
              logout(d=>{
                if (d.code == 200){
                  setData({...data,token:null});
                  setStatus({...status,login:false});
                  window.location.href="/";
                }else{
                  toastPresent(d.message);
                }
              });
            }}>
              <IonIcon slot="start" icon={logOutOutline} />
              <IonLabel>登出</IonLabel>
            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>


      {
        ["root","tech","oper"].indexOf( data.role ) >= 0 &&
        <IonCard>
          <IonCardSubtitle className={styles.cardTitle}>设备统计</IonCardSubtitle>
          <IonCardContent>
          
            <IonGrid className={styles.homeGrid}>
              <IonRow className={styles.homeRow}>

                <IonCol size="5" className={styles.homeCol}>
                  所有设备
                </IonCol>
                <IonCol size="5">
                  {home.allDeviceCount}
                </IonCol>
              </IonRow>
              
            </IonGrid>
          </IonCardContent>
        </IonCard>
      }      

      {
        ["root","tech","oper"].indexOf( data.role ) >= 0 &&

        <IonCard>
        <IonCardSubtitle className={styles.cardTitle}>用户统计</IonCardSubtitle>
          <IonCardContent>
          
            <IonGrid className={styles.homeGrid}>
              <IonRow className={styles.homeRow}>

                <IonCol size="5" className={styles.homeCol}>
                  所有用户
                </IonCol>
                <IonCol size="5">
                  {home.allUserCount}
                </IonCol>
              </IonRow>
              
            </IonGrid>
          </IonCardContent>
        </IonCard>
      }

      </IonContent>
    </IonPage>
  );
};

export default Home;
