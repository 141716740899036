import {useEffect,useContext,useState, Key} from 'react';

import { IonButton } from "@ionic/react";
import { restPostV1 } from "../hooks/AdminHttp";
import { Json } from "../common/types";

import config from "../config.json";
import React from 'react';


const BASE_URL = config["api.endport"]

const Ini2bin: React.FC<Json> = ({item}) =>{


    const [state,setState] = useState({
        label:"BIN",
        binFilename:null
    });

    useEffect(()=>{
        
        setState({
            label:"BIN",
            binFilename:null
        });
    },[item]);
    


    if (state.label === 'BIN'){

        
        console.log('label2: ' + state.label );


        return <IonButton size="small"  fill="outline" onClick={() => {
            setState({...state, label:'处理中'});

            restPostV1({res:'/v1/tool/ini2bin/config/' +item.filename, scb:(s)=>{
                
                setState({...state, label:'下载',binFilename:s.filename});

            },fcb:(f)=>{

                setState({...state, label:'BIN'});
        
            }});

        }} >
            
            <label htmlFor="file">{state.label} </label>
        </IonButton>;

      


    }else if( state.label === '处理中'){
        return  <label htmlFor="file">{state.label} </label>;

    }else if( state.label === '下载' ){

        return <a target='_blank' href={config["api.endport"] +  '/v1/file/config/' + state.binFilename}>下载</a>;
        
    }





}




export {Ini2bin};