
import {useEffect,useState,useMemo,useContext} from 'react';

import { IonCheckbox, IonInput,IonSelect,IonSelectOption,IonItem,IonLabel,IonRow } from "@ionic/react";
import './NullableMultiSelect.css';
import { Json } from '../common/types';

const NullableMultiSelect: React.FC<{
    value:string,
    values:String[],
    setValue:(v:string)=>void,
    required?:boolean
  }> = ({value,values,setValue,required}) => {

    if (required){
      return <IonSelect multiple value={value?value.split(','):value} onIonChange={(e) => setValue(e.detail.value.join(',')) }>
      {
      values.map((e:String)=>{
          return <IonSelectOption value={e}>{e}</IonSelectOption>
      })
      }
      </IonSelect>
    }else{
      return<>
      <IonSelect multiple value={value? value.split(','):value} onIonChange={(e) => setValue(e.detail.value.join(',')) }>
      {
      values.map((e:String)=>{
          return <IonSelectOption value={e}>{e}</IonSelectOption>
      })
      }
      </IonSelect>
      <IonCheckbox className='after' slot="end" checked={!value} onIonChange={e => {
          if (e.detail.checked){
            setValue(null);
          }
            // setValue( e.detail.checked?null:"");
          }} style={{}} ></IonCheckbox>
      </>;
    }


    


};



export default NullableMultiSelect;




