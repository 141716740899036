import {useEffect,useState,useMemo,useContext} from 'react';

import { IonCheckbox, IonInput,IonItem,IonLabel,IonRow } from "@ionic/react";
import './NullableTextField.css';

const NullableTextField: React.FC<{
    value:string,
    setValue:(v:string)=>void,
    required?:boolean
  }> = ({value,setValue,required}) => {

    if (required){
      return <IonInput value={value} onIonChange={e => {
        setValue( e.detail.value);
      }}></IonInput>
    }else{
      return <>
        <IonInput value={value} disabled={value == null} onIonChange={e => {
            setValue( e.detail.value);
        }}></IonInput>
        <IonCheckbox className='after' slot="end" checked={value == null} onIonChange={e => {
              setValue( e.detail.checked?null:'');
            }} style={{}} ></IonCheckbox>
      </>
    }



};



export default NullableTextField;


