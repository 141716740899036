enum CpuModel{
    GD32,AT32,STM32
}

enum UserRole {
    user,sale,support,repair,manuf,root
}

enum AdminRole {
    manuf,oper,tech,root
}

enum Life{
    submited,issued,ended
}

enum MessageWay{
    sms,email,notify,popup
}


var enums:{[key:string]:any} = {
    "user_role":UserRole,
    "admin_role":AdminRole,
    "life":Life,
    "message_way":MessageWay,
    "cpu_model":CpuModel
}

export default enums;
