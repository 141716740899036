import {useEffect, useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow,IonItem,IonLabel,IonInput,IonButton,
  useIonToast, useIonLoading} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';


import {AppContext} from '../global';
  
import {passwdLogin} from '../hooks/AdminHttp';

import styles from '../common/styles.module.css';

import {useHistory, useLocation} from 'react-router';


interface Json {[key:string]:any};


const PasswordLoginForm: React.FC<{
  history:any,
  context:any
}> = ({history,context}) => {



  const {data,setData,status,setStatus} = context;
  
  const [toastPresent,toastDismiss] = useIonToast();

  const [state,setState] = useState<Json>({});

  const [present, dismiss] = useIonLoading();

   

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>登录</IonTitle>
        </IonToolbar>
      </IonHeader>

      <>
      <IonContent fullscreen>
        <IonItem>
          <IonLabel position="floating">邮箱</IonLabel>
          <IonInput value={state.email} onIonChange={e => setState({...state,email: e.detail.value})}></IonInput>
        </IonItem>
       <IonItem>
          <IonLabel position="floating">密码</IonLabel>
          <IonInput value={state.passwd} onIonChange={e => setState({...state,passwd: e.detail.value})} type='password'></IonInput>
        </IonItem>

      </IonContent>
      <IonItem className={styles.bottom_bar} lines="none">
          <IonButton slot="end"  size="default" color="secondary" expand="block" fill="solid" disabled={!state.email || !state.passwd}
            onClick={()=>{

              present();

              passwdLogin({email:state.email,passwd:state.passwd},d=>{

                dismiss();

                if (d.code == 200){

                  setStatus({...status,login:true});

                  if (d.data && d.data.uuid && d.data.token){
                    setData({...data, uuid:d.data.uuid, token:d.data.token ,role:d.data.role, email:d.data.email});

                  }

                  console.log('success, will go to /');

                  history.replace('/');
                }else{
                  toastPresent({
                    message: d.message, 
                    duration: 3000,
                    color:'warning'
                  });
                }
              });
           
          }} >确认</IonButton>
      </IonItem>
      </>

    </IonPage>
  );
};

export default PasswordLoginForm;
