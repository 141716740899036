// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
// https://javascript.plainenglish.io/use-constructor-in-react-functional-component-with-custom-hooks-e0770e92b840
// https://blog.csdn.net/qq_20567691/article/details/106035547

import {useEffect,useState,useMemo,useContext, Key} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonBackButton,IonButtons,IonIcon,IonItem,IonButton,
  IonCard,IonImg,useIonModal,
  IonGrid,IonCol,IonRow,
  IonLabel,IonInput,IonSelect,IonSelectOption,
  useIonToast,
  IonModal,
  IonList,
  IonDatetime
} from '@ionic/react';


import { happy,chevronBackOutline } from 'ionicons/icons';

import { useHistory,useLocation } from "react-router";


import {restPost, uploadAsset, uploadBinary, uploadConfig} from "../hooks/AdminHttp";



import styles from '../common/styles.module.css'
import _styles from './GenericPost.module.css'
import UploaderField from '../components/UploaderField';
import { stat } from 'fs';
import UploadModal from '../components/UploadModal';
import ForeignModal from '../components/ForeignModal';
import enums from '../type/enums';
import NullableTextField from '../components/NullableTextField';
import GenericField from '../components/GenericField';
Object.assign(styles,_styles);


interface Json {[key:string]:any};

const GenericPost: React.FC<{
  // history:any,
  // context:any,
  meta:Json,
  entities:Json,
  onCloseClicked:()=>void
}> = ({meta, entities, onCloseClicked}) => {

  const [state,setState] = useState<{[key:string]:any}>({});

  const [item,setItem] = useState<{[key:string]:any}>({});


  const [toastPresent,toastDismiss] = useIonToast();

  useEffect(()=>{


  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>

          <IonTitle>添加{meta.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onCloseClicked}>取消</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <IonList inset={true}>
        {
          meta.post_fields.map((key:string,index:Number)=>{
            let fieldMeta = meta.fields[key ];
            // fieldMeta.key = key;

            return <IonItem key={key}>
              <IonLabel position={"floating"}>{fieldMeta.name}</IonLabel>
              <GenericField  value={item[key]} setValue={(v:any,...attachs:any)=>{

                
                console.log('set value: ' + key + ',' + v);

                if (v === undefined) return;


                if (fieldMeta.attach_field && attachs){
                  setItem({...item,[key]: v, [fieldMeta.attach_field]:attachs[0]});
                }else{

                  setItem({...item,[key]: v});
                }


                console.log('item:' + JSON.stringify(item));

              }} fieldMeta={fieldMeta} entities={entities[(fieldMeta.key ||  key) + 's']} />
    
            </IonItem>;



            // if('date' === field.type){

            //   return <IonItem>
            //     <IonLabel>{field.name}</IonLabel>
            //     <IonDatetime value={item[key]} displayFormat='YY-MM-DD HH:mm:ss' pickerFormat='YY-MM-DD HH:mm:ss' onIonChange={
            //       (e)=>setItem({...item,[key]:e.detail.value})
            //     } ></IonDatetime>
                
            //   </IonItem>
              
            // }else if('enum' === field.type){
            //   console.log( Object.values (enums[field.enum]) );

            //   return <IonItem>
            //     <IonLabel>{field.name}</IonLabel>
            //     <IonSelect value={item[key]} onIonChange={(e) => setItem({...item,[key]: e.detail.value })}>
            //       {
            //         Object.values(enums[field.enum]).filter(value => typeof value === 'string').map((e:any)=>{
            //           return <IonSelectOption value={e}>{e}</IonSelectOption>
            //         })
            //       }
            //     </IonSelect>
                
            //   </IonItem>
              
            // }else if ('file' === field.type){
            //   return <div key={key as Key}><IonItem button={true} detail={true} onClick={()=>{
            //     setState({...state, [key+'_open']:true});
            //     // alert('set true');
            //   }}>
            //     <IonLabel>{field.name}</IonLabel>
            //     <IonInput readonly={true} value={item[key]} className={styles.input}></IonInput>
                
            //   </IonItem>
            //   <IonModal isOpen={state[key + '_open' ]}>
            //       <UploadModal meta={field} onConfirmed={(f)=> {
            //         // if ('config' === field.filetype){

            //         //   uploadConfig(localFile,(f)=>{
            //         //     setState({[key]:f});
                        
            //         //   });
            //         // }

            //         setState({...state, [key+'_open']:false});
            //         setItem({...item,[key]:f});
                    
                    
            //       } } onCanceled={()=>{
            //         setState({...state, [key+'_open']:false});

            //         }} />
            //     </IonModal>
            //   </div>;

            // }else if('foreign' === field.type){
            //   return  <IonItem>
            //   <IonLabel>{field.name}</IonLabel>

            //   <IonSelect value={item[key]} onIonChange={(e) => setItem({...item,[key]: e.detail.value })}>
            //   {
            //     Object.values( entities[(field.key ||  key) + 's']).map((e:any)=>{
            //       return <IonSelectOption value={e.uuid}>{e.name}</IonSelectOption>
            //     })
            //   }
            //   </IonSelect>
              
            // </IonItem>
            // }else if(false){
            //   return <div key={key as Key}><IonItem button={true} detail={true} onClick={()=>{
            //     setState({...state, [key+'_open']:true});
            //     // alert('set true');
            //   }}>
            //     <IonLabel>{field.name}</IonLabel>
            //     <IonInput readonly={true} value={state[key] && state[key].name} className={styles.input}></IonInput>
                
            //   </IonItem>
            //   <IonModal isOpen={state[key + '_open' ]}>
            //       <ForeignModal name={field.name} res={field.foreign} onConfirmed={(f)=> {

            //         setState({...state, [key+'_open']:false,[key]:f});
            //         // setItem({...item, [key ]:f.uuid});
            //         setItem({...item, [key ]:{uuid:f.uuid}});
                    
            //       } } onCanceled={()=>{
            //         setState({...state, [key+'_open']:false});

            //         }} />
            //     </IonModal>
            //   </div>;
            // }else{
            //   return <IonItem key={key as Key}>
            //     <IonLabel position="floating">{field.name}</IonLabel>
            //     <NullableTextField value={item[key as keyof typeof item]} setValue={v => setItem({...item,[key as keyof typeof state]: v})}></NullableTextField>
            //   </IonItem>;

            //   // return <IonItem key={key as Key}>
            //   //   <IonLabel position="floating">{field.name}</IonLabel>
            //   //   <IonInput value={item[key as keyof typeof item]} onIonChange={e => setItem({...item,[key as keyof typeof state]: e.detail.value})}></IonInput>
            //   // </IonItem>;
            // }

            
          })
        }
      </IonList>

      </IonContent>


      <IonItem lines="none">
          <IonButton slot="end"  size="default" color="secondary" expand="block" fill="solid" onClick={()=>{

            
            restPost(meta.post_path,null,item,(resp:any)=>{
              console.log('resp: ' + resp);
              toastPresent({
                message:"提交成功", 
                duration: 3000,
                color:'success' 
              });

              onCloseClicked();

            },(msg:any)=>{
              toastPresent({
                message:msg, 
                duration: 3000,
                color:'danger' 
              });
            });
          }} >确认</IonButton>
      </IonItem>

    </IonPage>
  );
};

export default GenericPost;