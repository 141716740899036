
import axios from 'axios';

import { Json } from "../common/types";


import config from "../config.json";
const BASE_URL = config["api.endport"]


export const genericPatchForeign = (entity:string,uuid:string, foreign:string, foreignUuid:string ,
    scb?:(args:any)=>void, fcb?:(arg:string)=>void,ecb?:(arg:string)=>void)=>{
  
    let config:Json = {
      method:'patch',
      baseURL:BASE_URL,
      url: '/admin/rest/generic/' + entity + '-foreign/' + uuid + '/' + foreign ,
      data:{uuid:foreignUuid},
      withCredentials:true
    }

  
    axios(config).then(response => {
  
      console.log("===data=== " + JSON.stringify( response.data));
  
      scb(response.data)
  
    },response =>{
      console.log("===failure=== " + JSON.stringify( response));
  
      if (fcb){
        fcb(response.message);
      }
    }).catch(function (error) {
      console.log("===error=== " + error);
      
      if (ecb){
        ecb(error);
      }
    });
  
  }