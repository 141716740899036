// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
// https://javascript.plainenglish.io/use-constructor-in-react-functional-component-with-custom-hooks-e0770e92b840
// https://blog.csdn.net/qq_20567691/article/details/106035547

import {useEffect,useState,useMemo,useContext} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonBackButton,IonButtons,IonIcon,IonItem,IonButton,
  IonCard,IonImg,useIonModal,
  IonGrid,IonCol,IonRow,
  IonTextarea,
  IonLabel,IonInput,IonSelect,IonSelectOption,
  IonDatetime,
  useIonToast,
  IonCheckbox
} from '@ionic/react';


import { happy,chevronBackOutline } from 'ionicons/icons';

import { useHistory,useLocation } from "react-router";

import moment from 'moment';

import config from "../config.json";

import JsonView from "../components/JsonView";

import {restPost, useRestGet} from "../hooks/AdminHttp";

import styles from '../common/styles.module.css'
import { Json } from '../common/types';
import GenericPatch from './GenericPatch';
import { stat } from 'fs';
// import _styles from './GetOrder.module.css'
// Object.assign(styles,_styles);


const GenericDetail: React.FC<{
  // history:any,
  // context:any,
  meta:Json,
  item?:Json,
  entities:Json,
  onCloseClicked:()=>void
}> = ({meta, item,entities, onCloseClicked}) => {

  const [state,setState] = useState<{[key:string]:any}>(item?item:{});

  const [detail,loadDetail] = useRestGet(meta.load_path + '/' + item.uuid);

  const [status,setStatus] = useState<Json>({});

  const [toastPresent,toastDismiss] = useIonToast();

  const [presentPatch, dismissPatch] = useIonModal(GenericPatch, {
    meta:meta,
    field:status.field,
    item: status.item,
    entities:entities,
    onCloseClicked: ()=>{
      dismissPatch();
      if (meta.load_path){
        loadDetail();
      }
    }
  });

  useEffect(()=>{

  },[]);

  useEffect(
    ()=>{
      if (detail){
        setState({...state, ...detail});
      }
    },
    [detail]
  );


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>

          <IonTitle>{meta.name}详情</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onCloseClicked}>关闭</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonGrid className={styles.pagiGrid}>

          {
            meta.detail_fields.map((key:string)=>{

              console.log('key: ' + key);

              var field = meta.fields[key];

              return <IonRow key={key}  className={styles.pagiLine}>
                <IonCol size="4">
                  {field.name}
                </IonCol>
                <IonCol size="6">

                {
                field.type == 'bool' &&
                    <IonCol size={field.size?field.size:1}>
                      <IonCheckbox checked={state[key]} disabled={true}></IonCheckbox>
                    </IonCol>
                }
                {field.type == 'date' &&
                  <IonCol size="2">{new Date(state[key]).toString() }</IonCol>
                }

                {field.type == 'foreign' && 
                  <IonCol size="2">{ field.cached && state[key]? entities[(field.key||key)+ 's'][state[key]].name:''}</IonCol>
                }

                { field.type != 'data' && field.type != 'foreign' &&
                  <IonCol size="2">{state[key]}</IonCol>
                }
                
                 


                </IonCol>
                <IonCol size="2">
                  {meta.patch_fields.indexOf(key) >= 0 && 
                    <IonButton size="small" fill="outline" onClick={()=>{

                      console.log('state::' + JSON.stringify(state));

                        setStatus({field:key,item:state});
                        presentPatch();
                    }}>修改</IonButton>
                  } 
                  {field.type==='file' &&
                    <a target='_blank' href={config["api.endport"] + "/file/" + field.file_type + '/' + state[key]} >下载</a>
                  }
                </IonCol>
              </IonRow>
            })
          }


        <IonRow key={'created_date'} className={styles.pagiLine}>
        
          <IonCol size="4">
            添加日期
          </IonCol>
          <IonCol size="8">
            { moment(item.created_date).format("MM-DD,HH:mm") }
          </IonCol>

        </IonRow>
      
        
        
        </IonGrid>


      </IonContent>



    </IonPage>
  );
};

export default GenericDetail;