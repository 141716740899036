import {useEffect, useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow,IonItem,IonLabel,IonInput,IonButton,
  IonModal,IonLoading,
  useIonToast, useIonLoading} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';

import styles from './Login.module.css';

import {AppContext} from '../global';
  
import {tokenLogin,sendSms} from '../hooks/AdminHttp';


import {useHistory, useLocation} from 'react-router';

import PasswordLoginForm from '../modal/PasswordLoginForm';


interface Json {[key:string]:any};

const Login: React.FC = () => {

  // const { name } = useParams<{ name: string; }>();


  const history = useHistory();

  const context = useContext(AppContext);

  const {data,setData,status,setStatus} = context;
  
  const [toastPresent,toastDismiss] = useIonToast();

  const [state,setState] = useState<Json>({});

  const [present, dismiss] = useIonLoading();


  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
 
    console.log('data:' + JSON.stringify(data));
    
    
    if (!data.uuid || !data.token){

      setStatus({...status,login:false});
      return;
    }

    // present({enterAnimation:undefined,leaveAnimation:undefined});

    setShowLoading(true)

    
      tokenLogin({ 
          uuid:data.uuid,
          token:data.token
      },resp => {

        
          console.log('will dismiss loading dialog..');
          // dismiss();
          setShowLoading(false);

          if (resp.code == 200){


              setStatus({...status,login:true});
              setData({...data, uuid:resp.data.uuid, token:resp.data.token ,role:resp.data.role,email:resp.data.email});
          
              history.replace('/');

              console.log('resp code 200 ..');  
            
            
          } else{
            setStatus({...status,login:false});
            setData({...data, token:null});

            toastPresent({
              message: resp.message, 
              duration: 3000,
              color:'warning'
            });

            console.log('resp code  ..' + resp.code);  
          
          }


          
      },fail=>{
        // dismiss();
        setShowLoading(false);
        toastPresent({
          message: fail, 
          duration: 3000,
          color:'warning'
        });

      },error=>{
        // dismiss();

        setShowLoading(false);
        toastPresent({
          message: error, 
          duration: 3000,
          color:'danger'
        });
      });

    
  }, []);



  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>登录</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
      </IonContent>

        <IonModal isOpen={!status.login && !data.token }>
          <PasswordLoginForm history={history} context={context} />
        </IonModal>

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />

    </IonPage>
  );
};

export default Login;
