// https://zhuanlan.zhihu.com/p/162942121



import { Route, Redirect } from 'react-router-dom';



export const ProtectedRoute:React.FC<{children:any, auth?:boolean,redirect?:string,[x:string]:any}> = ({children, auth=false,redirect="/", ...rest })=>(

      <Route {...rest}>
        {auth?children:<Redirect to={redirect} />}
      </Route>


)
