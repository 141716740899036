import { IonButton, IonCheckbox, IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from "@ionic/react";
import NullableSelect from "./NullableSelect";
import NullableMultiSelect from "./NullableMultiSelect";
import { uploadGeneric } from "../hooks/AdminHttp";
import NullableTextField from "./NullableTextField";
import enums from "../type/enums";
import { Json } from "../common/types";
import { useState } from "react";


const GenericField:React.FC<{value:any, setValue:(v:any,...attachs:any)=>void , fieldMeta:Json , entities:any}>=({value,setValue,fieldMeta,entities})=>{

    const [state,setState] = useState<{[key:string]:any}>({});

    switch(fieldMeta.type){

      case 'date':
        return <IonItem>
        <IonLabel>{fieldMeta.name}</IonLabel>
        <IonDatetime value={value} displayFormat='YY-MM-DD HH:mm:ss' pickerFormat='YY-MM-DD HH:mm:ss' onIonChange={
          (e)=>setValue( e.detail.value )
        } ></IonDatetime>
        
      </IonItem>  
  
      case 'enum': 
  
        return  <NullableSelect value={value} values={Object.values(enums[fieldMeta.enum]).filter(value => typeof value === 'string') as String[] } 
          setValue={(e) => setValue(e)} required={fieldMeta.required}>
        </NullableSelect>;
      
          // return  <IonSelect value={value} onIonChange={(e) => setValue(e.detail.value) } >
          //   {
          //     Object.values(enums[fieldMeta.enum]).filter(value => typeof value === 'string').map((e:any)=>{
          //       return <IonSelectOption value={e}>{e}</IonSelectOption>
          //     })
          //   }
          //   </IonSelect>;
  
      case 'bool':
          return <>
            <IonInput hidden value=" "></IonInput>
            <IonCheckbox checked={value} style={{marginTop:'12px'}} onIonChange={e => setValue(e.detail.checked)}>{fieldMeta.values[value?1:0]}</IonCheckbox>
          </>
  
      case 'foreign':
          return <IonSelect value={value} onIonChange={ (e) => setValue(e.detail.value)}>
            {
              Object.values( entities).map((e:any)=>{
                return <IonSelectOption key={e.uuid} value={e.uuid}>{e.name}</IonSelectOption>
              })
            }
            </IonSelect>;
      case 'foreign_name':
        if (fieldMeta.multiple){
          return <NullableMultiSelect value={value} values={Object.values(entities).map((e:Json)=>e.name) } setValue={(e) =>setTimeout(
            ()=>setValue(e),10
          )}>
          </NullableMultiSelect>;
        }else{

          return  <NullableSelect value={value} values={Object.values(entities).map((e:Json)=>e.name) } setValue={(e) => setValue(e)}>
            </NullableSelect>;
        }
             
      case 'file':
          return <>
            <IonInput hidden value=" "></IonInput>
            <div style={{marginTop:'12px'}}>{value}</div>
            <input id={"file-" +  fieldMeta.key} hidden type="file" accept="ini" onChange={
                  (ev)=> {

                    console.log('file,' + fieldMeta.name);
                            
                                // setState({binary_file:ev.target.files[0]});
                                uploadGeneric(ev.target.files[0],fieldMeta.file_type,fieldMeta.file_suffix,()=>{},(f)=>{
            
                                  console.log('ret:' + fieldMeta.key + ',' + JSON.stringify(f));
                                  setValue(f.filename,f.md5);

                                  setState({uploading:false});
                                },(err)=>{
                                  console.log('error:' + err);
                                  setState({uploading:false});
                                });

                                setState({uploading:true});
            
                          }

                } />

            <IonButton fill="outline" slot="end" style={{ marginTop: '28px' }} onClick={() => {
              (document as any).getElementById("file-" + fieldMeta.key ).click();
            }} >
                
                <label htmlFor="file">{state.uploading?'上传中...':'选择文件'} </label>
            </IonButton>
          </>
      //     if ( value )
      //       return  <div>已上传成功：{value}</div>;
      //     else return <IonButton fill="outline" slot="end" style={{ marginTop: '18px' }}>
      //     <input id="file" hidden type="file" accept="ini" onChange={
      //       (ev)=> {
                      
      //                     // setState({binary_file:ev.target.files[0]});
      //                     uploadGeneric(ev.target.files[0],fieldMeta.file_type,fieldMeta.file_suffix,()=>{},(f)=>{
      
      //                       setValue(f.filename);
      //                     });
      
      //               }

      //     } />
      //     <label htmlFor="file">Selecionar arquivo*</label>
      // </IonButton>;
          
          // <input type="file" onChange={(ev)=> {
                      
          //             // setState({binary_file:ev.target.files[0]});
          //             uploadGeneric(ev.target.files[0],fieldMeta.file_type,fieldMeta.file_suffix,()=>{},(f)=>{
  
          //               setValue(f.filename);
          //             });
  
          //       }}></input>
            
      default:
            return  <NullableTextField value={value} setValue={setValue} required={fieldMeta.required}></NullableTextField>;
  
  
    }
  }


export default GenericField;