// https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
// https://javascript.plainenglish.io/use-constructor-in-react-functional-component-with-custom-hooks-e0770e92b840
// https://blog.csdn.net/qq_20567691/article/details/106035547

import {useEffect,useState,useMemo,useContext} from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar ,IonBackButton,IonButtons,IonIcon,IonItem,IonButton,
  IonCard,IonImg,useIonModal,
  IonGrid,IonCol,IonRow,
  IonTextarea,
  IonLabel,IonInput,IonSelect,IonSelectOption,
  IonDatetime,
  useIonToast,
  IonCheckbox
} from '@ionic/react';


import { happy,chevronBackOutline } from 'ionicons/icons';

import { useHistory,useLocation } from "react-router";



import {restPatch,restPost,uploadAsset,uploadBinary, uploadGeneric} from "../hooks/AdminHttp";
import enums from '../type/enums';

import styles from '../common/styles.module.css'



interface Json {[key:string]:any};

const GenericImport: React.FC<{
  meta:Json,
  onCloseClicked:()=>void
}> = ({meta, onCloseClicked}) => {


  const [state,setState] = useState<{[key:string]:any}>({});

  const [toastPresent,toastDismiss] = useIonToast();


  useEffect(()=>{

  },[]);




  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>

          <IonTitle>导入{meta.name}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onCloseClicked}>取消</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>


        <IonItem>
          <IonLabel position="floating">导入数据</IonLabel>
            
          {
            state['filename'] &&
            <IonItem>
              <IonLabel>已上传成功：{state['filename']}</IonLabel>
            </IonItem>
          }
          {
            !state['filename'] &&
            <IonItem>
              <IonLabel>上传文件</IonLabel>
              <br></br>
              <div>
              <input type="file" onChange={(ev)=> {
                    console.log(ev.target.files[0]);

                    setState({binary_file:ev.target.files[0]});

              }}></input>
              </div>
              <IonButton onClick={()=>{
                uploadGeneric(state.binary_file,'import_','csv',()=>{},(f)=>{

                  setState({...state,'filename':f.filename});
                });
              }}>上传</IonButton>
              
            </IonItem>
          }


        </IonItem>


      

       
      </IonContent>


      <IonItem lines="none">
          <IonButton slot="end"  size="default" color="secondary" expand="block" fill="solid" onClick={()=>{
          

              restPost(meta.import_path , null ,{'filename':state['filename']},(resp:any)=>{
                console.log('resp: ' + resp);
                toastPresent({
                  message:"提交成功", 
                  duration: 3000,
                  color:'success' 
                });
  
                onCloseClicked();
  
              },(msg:any)=>{
                toastPresent({
                  message:msg, 
                  duration: 3000,
                  color:'danger' 
                });
              });
            
          }} >确认</IonButton>
      </IonItem>

    </IonPage>
  );
};

export default GenericImport;