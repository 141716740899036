import {useEffect,useContext, useState} from 'react';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar,
  IonGrid,IonCol,IonRow ,IonButton,IonLabel,
  IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
  IonItem,
  useIonToast,
  IonIcon,
  IonInput} from '@ionic/react';
import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';

import {AppContext} from '../global';


import '../common/styles.css';

import styles from './Tool.module.css';

import {useHistory, useLocation} from 'react-router';
import {useRestGet,logout, uploadGeneric, restPost, restPostV1} from '../hooks/AdminHttp';
import { logOutOutline, personOutline } from 'ionicons/icons';
import { stat } from 'fs';

import config from "../config.json";

const Tool: React.FC = () => {

  const [state,setState] = useState<{[key:string]:any}>({});

  useEffect(()=>{
  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonMenuButton slot="start"/>
          <IonTitle>工具</IonTitle>
          

        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>


      <IonCard>
        <IonCardSubtitle className={styles.cardTitle}>INI转BIN</IonCardSubtitle>
          <IonCardContent>
          
        <IonItem>
          <IonLabel position="floating">Step1 - 上传INI文件</IonLabel>

          <IonInput hidden value=" "></IonInput>
            <div style={{marginTop:'12px',visibility:state.iniFilename?'visible':'hidden' }}>
              {state.iniFilename} [md5:{state.iniMd5}]
            </div>
            <form id="form-tool-ini">
            <input id={"file-tool-ini" } hidden type="file" accept="ini" onChange={
                  (ev)=> {

                                // setState({binary_file:ev.target.files[0]});
                                uploadGeneric(ev.target.files[0],'tool','ini',()=>{},(f)=>{
            
                                  setState({...state, iniFilename:f.filename, iniMd5:f.md5, uploading:false} );

                                },(err)=>{
                                  console.log('error:' + err);
                                  setState({uploading:false});
                                });

                                setState({...state,uploading:true});
            
                          }

                } />
                </form>

            <IonButton fill="outline" slot="end" style={{ marginTop: '28px',width:'92px' }} onClick={() => {
                 (document as any).getElementById("form-tool-ini").reset();

                 (document as any).getElementById("file-tool-ini").click();
            }} >
                
                <label htmlFor="file">{state.uploading?'上传中...':'上传INI文件'} </label>
            </IonButton>
            </IonItem>


        <IonItem>
          <IonLabel position="floating">Step2 - 生成BIN文件</IonLabel>

          <IonInput hidden value=" "></IonInput>
            <div style={{marginTop:'12px',visibility:state.binFilename?'visible':'hidden' }}>
              {state.binFilename} [md5:{state.binMd5}] &nbsp;&nbsp;
              <a target='_blank' href={config["api.endport"] +  '/v1/file/tool/' + state.binFilename}>下载</a>
            </div>

            
            <IonButton fill="outline" slot="end" style={{ marginTop: '28px',width:'92px' }} onClick={() => {

              if (!state.iniFilename){
                alert('请先上传INI文件！');
                return;
              }


              restPostV1({res:'/v1/tool/ini2bin/tool/' +state.iniFilename,scb:(s)=>{
                setState({...state,binFilename:s.filename, binMd5:s.md5, converting:false} );
              },fcb:(f)=>{
                setState({...state,converting:false});
              }});


              setState({...state,converting:true});

            }} >
                
                <label htmlFor="file">{state.converting?'生成中...':'生成BIN文件'} </label>
            </IonButton>
            </IonItem>
            
          </IonCardContent>
        </IonCard>


      </IonContent>
    </IonPage>
  );
};

export default Tool;
